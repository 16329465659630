module.exports = {
	siteMetadata: {
		title: 'Alex Schoemaker',
		description: 'Our Hydrangeas are easy to maintain, can withstand severe winters, bloom every summer and last a lifetime.',
		keywords: 'Alex, Schoemaker, Hydrangea, paniculata, Hydrangeas, breeding, collection, hortensia, hortensien, pluimhortensia, young, plants, visual, testkit, nursery, boskoop',
		author: 'Tijs Luitse',
		siteUrl: `https://alexschoemaker.nl`,
	},
	plugins: [
		`gatsby-transformer-sharp`,
		`gatsby-plugin-sharp`,
		`gatsby-plugin-react-helmet`,
		{
			resolve: `gatsby-source-storyblok`,
			options: {
				accessToken: 'qVMTlsfIYllAlJlhZyIA1Qtt',
				homeSlug: 'home',
				version: process.env.NODE_ENV === 'production' ? 'published' : 'draft'
			}
		},
		{
			resolve: `gatsby-plugin-i18n`,
			options: {
				langKeyDefault: 'en',
				langKeyForNull: 'en',
				prefixDefault: false,
				useLangKeyLayout: false
			}
		},
		{
			resolve: 'gatsby-plugin-react-svg',
			options: {
				rule: {
					include: /images/
				}
			}
		},
		{
			resolve: `gatsby-plugin-sass`,
			options: {
				sassOptions: {
					includePaths: [
						`${__dirname}/src/styles/partials/mixins`,
						`${__dirname}/src/styles/partials/mq`
					],
				},
				cssLoaderOptions: {
					camelCase: false
				}
			}
		},
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: `Alex Schoemaker`,
				short_name: `Alex Schoemaker`,
				start_url: `/`,
				background_color: `#663399`,
				theme_color: `#663399`,
				display: `minimal-ui`,
				icon: `${__dirname}/src/images/favicon.jpg`
			}
		}
	]
}
